import React from 'react';
import { navigate } from 'gatsby';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    _initFirebase = false;

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      this._initFirebase = false;
      if (this.listener) {
        this.listener();
      }
    }

    firebaseInit = () => {
      const { firebase } = this.props;
      if (firebase && !this._initFirebase) {
        this._initFirebase = true;
        this.listener = firebase.onAuthUserListener(
          authUser => {
            if (!condition(authUser)) {
              navigate('/account/login');
            }
          },
          () => navigate('/account/login'),
        );
      }
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => condition(authUser) && <Component {...this.props} />}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithAuthorization);
};

export default withAuthorization;
