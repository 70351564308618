import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../../layouts';
import Container from '../../components/Container';

import { destroy } from '../../components/Cart/storage';
import { AuthUserContext } from '../../components/Session';

import loadComponents from '../../components/Loadable';
import PageTitle from '../../components/Subpage/title';

const Button = loadComponents('button');
const Link = loadComponents('link');

export default class CheckoutCompletePage extends React.Component {
  componentDidMount() {
    if (typeof window !== `undefined` && window.history.state === null) {
      navigate('/');
    } else {
      destroy();
    }
  }

  render() {
    const page = { name: 'Order Complete', slug: 'checkout/complete' };

    const { location } = this.props;

    const prevPage = location.state && location.state.prevPage;

    const confirmation =
      typeof window !== `undefined` && window.history.state !== null
        ? window.history.state.confirmation
        : false;

    return (
      confirmation && (
        <Layout
          title={page.name}
          location={`/${page.slug}/`}
          prevPage={prevPage}
        >
          <PageTitle title="Your order has been confirmed" />
          <AuthUserContext.Consumer>
            {authUser => (
              <Container>
                <p>
                  We have emailed a confirmation of your order to:{' '}
                  <strong>{confirmation?.billing?.email}</strong>.<br />
                  Any order updates will also be sent to this address.
                </p>
                <ul>
                  <li>
                    <strong>Order Number: </strong>
                    <span>#{confirmation?.id}</span>
                  </li>
                  <li>
                    <strong>Shipping Address: </strong>
                    <span>
                      {confirmation?.shipping?.address_1},{' '}
                      {confirmation?.shipping?.city},{' '}
                      {confirmation?.shipping?.state},{' '}
                      {confirmation?.shipping?.postcode}
                    </span>
                  </li>
                  <li>
                    <strong>Total Paid: </strong>
                    <span>&pound;{confirmation?.total}</span>
                  </li>
                </ul>
                <p>
                  If you have any questions about your order.{' '}
                  <Link to="/contact/" content="Click here" /> to get in touch
                </p>
                <Button to="/" style={buttonStyle} primary>
                  Return to Homepage
                </Button>
                {authUser && (
                  <Button
                    to="/account/orders/"
                    style={buttonStyle}
                    secondary
                    dark
                  >
                    View My Orders
                  </Button>
                )}
              </Container>
            )}
          </AuthUserContext.Consumer>
        </Layout>
      )
    );
  }
}

const buttonStyle = {
  width: `300px`,
  maxWidth: `100%`,
  marginTop: `15px`,
};
