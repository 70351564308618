import React from 'react';

import { withUser } from './context';
import { withFirebase } from '../Firebase';
import Container from '../Container';

import alertStyle from '../Alert/style';

import loadComponents from '../Loadable';

const Button = loadComponents('button-input');
const Alert = loadComponents('alert');
const H2 = loadComponents('h2');

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    _isMounted = false;

    constructor(props) {
      super(props);
      this.state = { isSent: false };
    }

    componentDidMount() {
      this._isMounted = true;
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    onSendEmailVerification = () => {
      const { firebase } = this.props;
      const { doSendEmailVerification, auth } = firebase;
      doSendEmailVerification(auth.currentUser).then(
        () => this._isMounted && this.setState({ isSent: true }),
      );
    };

    refreshPage = () => {
      window.location.reload();
    };

    render() {
      const { authUser } = this.props;
      const { isSent } = this.state;
      return needsEmailVerification(authUser) ? (
        <Container>
          <H2>Please verify your email address</H2>
          {isSent ? (
            <>
              <Alert style={alertStyle} success>
                Email confirmation sent.
              </Alert>
              <p>
                Please check you email inbox (including your spam folder) and
                click the link in the email to activate your account. Once
                activated refresh this page to access your account.
              </p>
            </>
          ) : (
            <p>
              You must activate your account before you can access this page.
              Please check you email inbox (including your spam folder) and
              click the link in the email to activate your account. If you have
              not received your confirmation email use the button below to
              re-send confirmation.
            </p>
          )}
          <Button
            value="Send confirmation E-Mail"
            onClick={this.onSendEmailVerification}
            disabled={isSent}
            primary
            style={{
              opacity: isSent && 0.4,
              pointerEvents: isSent && 'none',
              maxWidth: '300px',
            }}
          />
          <Button
            value="I have already verified"
            onClick={this.refreshPage}
            secondary
            style={{
              maxWidth: '300px',
              marginLeft: `0.625rem`,
            }}
          />
        </Container>
      ) : (
        <Component {...this.props} />
      );
    }
  }

  return withUser(withFirebase(WithEmailVerification));
};

export default withEmailVerification;
